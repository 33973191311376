<template>
  <div class="ma-4">
    <div class="display-2 font-weight-bold my-6 text-capitalize">
      {{ displayName }}
    </div>

    <div class="my-6">
      <v-row>
        <v-col cols="12" md="6">
          <v-form ref="form" class="onboarding-question-form">
            <label class="text-field-label">Onboarding Question</label>
            <v-text-field
              flat
              solo
              required
              class="mt-2"
              v-model="form.body"
              :error-messages="form.$getError('body')"
              :loading="loading"
            ></v-text-field>

            <label class="text-field-label">Description</label>
            <v-textarea
              flat
              solo
              required
              rows="3"
              class="mt-2"
              v-model="form.description"
              :error-messages="form.$getError('description')"
              counter
              maxlength="255"
              hide-details="auto"
              :loading="loading"
            ></v-textarea>

            <label class="text-field-label">Sub Questions</label>
            <v-text-field
              flat
              solo
              class="mt-2"
              :hide-details="form.sub_questions.length > 0"
              v-model="subQuestionInput"
              :loading="loading"
              @keydown.enter="onAddSubQuestion"
            ></v-text-field>
            <v-list
              flat
              class="pa-0"
              :class="form.sub_questions.length > 0 ? 'mb-5' : ''"
            >
              <v-list-item
                class="pa-0"
                v-for="(subQuestion, index) in form.sub_questions"
                :key="index"
              >
                <v-list-item-content class="px-0">
                  <v-list-item-title class="text-wrap">
                    {{ subQuestion.name }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon
                  class="my-3"
                  @click="onRemoveSubQuestion(index)"
                >
                  <v-icon color="error">{{ icons.delete }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>

            <label class="text-field-label">Tips</label>
            <v-text-field
              flat
              solo
              required
              class="mt-2"
              :hide-details="form.tips.length > 0"
              v-model="tipInput"
              :loading="loading"
              @keydown.enter="onAddTip"
            ></v-text-field>
            <v-list
              flat
              class="pa-0"
              :class="form.tips.length > 0 ? 'mb-5' : ''"
            >
              <v-list-item
                class="pa-0"
                v-for="(tip, index) in form.tips"
                :key="index"
              >
                <v-list-item-content class="px-0">
                  <v-list-item-title class="text-wrap">
                    {{ tip.name }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon class="my-3" @click="onRemoveTip(index)">
                  <v-icon color="error">{{ icons.delete }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>

            <label class="text-field-label">Choices</label>
            <v-text-field
              flat
              solo
              required
              class="mt-2"
              hide-details="auto"
              v-model="choiceInput"
              :loading="loading"
              @keydown.enter="onAddChoice"
              :error-messages="form.$getError('choices')"
            ></v-text-field>

            <v-expansion-panels
              class="mt-2"
              :class="form.choices.length > 0 ? 'mb-8' : 'mb-6'"
            >
              <v-expansion-panel
                v-for="(choice, cIndex) in form.choices"
                :key="cIndex"
              >
                <v-expansion-panel-header>
                  {{ choice.name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div>
                    <div>
                      <v-autocomplete
                        style="z-index:100"
                        flat
                        solo
                        dense
                        required
                        class="mt-2"
                        hide-details
                        v-model="resourceInput"
                        :loading="resourceIsLoading"
                        label="Enter related resources"
                        item-text="name"
                        item-value="id"
                        :items="resourcesItems"
                        :search-input.sync="searchResourceName"
                        return-object
                        @change="onAddResource(cIndex)"
                      ></v-autocomplete>
                    </div>
                    <v-list flat class="pa-0 mt-2 mb-5">
                      <v-list-item
                        class="pa-0"
                        v-for="(resource, rIndex) in choice.resources"
                        :key="rIndex"
                      >
                        <v-list-item-content class="px-0">
                          <v-list-item-title class="text-wrap">
                            <router-link
                              class="text-decoration--none"
                              target="_blank"
                              :to="{
                                name: 'milestone.resources.details',
                                params: { id: resource.id }
                              }"
                              >{{ resource.name }}
                            </router-link>
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon
                          class="my-3"
                          @click="onRemoveResource(cIndex, rIndex)"
                        >
                          <v-icon color="error">{{ icons.delete }}</v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                    </v-list>
                  </div>
                  <div class="text-right my-2">
                    <v-btn color="error" small @click="onRemoveChoice(cIndex)"
                      >Remove Choice</v-btn
                    >
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <label class="text-field-label">Is Multiple Choice?</label>
            <v-select
              flat
              solo
              required
              class="mt-2"
              v-model="form.is_multiple"
              :items="[
                { value: 1, label: 'Yes' },
                { value: 0, label: 'No' }
              ]"
              item-value="value"
              item-text="label"
              :error-messages="form.$getError('is_multiple')"
              :loading="form.$busy"
            ></v-select>

            <v-spacer></v-spacer>

            <div class="d-flex  justify-space-between  mb-16">
              <div>
                <v-btn
                  color="error"
                  @click="deleteConfirmation = true"
                  class="px-6"
                  height="40px"
                  v-show="this.questionId"
                >
                  Delete
                </v-btn>
              </div>

              <div>
                <v-btn
                  color="primary"
                  class="px-6"
                  height="40px"
                  :loading="form.$busy"
                  @click="submit"
                >
                  {{ this.questionId ? 'Update' : 'Save' }}
                </v-btn>
              </div>
            </div>
          </v-form>
        </v-col>
      </v-row>
    </div>

    <ConfirmModal
      v-model="deleteConfirmation"
      title="Confirm Delete"
      message="Are you sure you want to delete this?"
      :width="320"
    >
      <v-btn text @click="deleteConfirmation = false" :loading="form.$busy">
        Cancel
      </v-btn>
      <v-btn color="error" text @click="deleteQuestion()">Yes, Proceed</v-btn>
    </ConfirmModal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Form from '../../../../utils/form'
import {
  mdiChevronLeft,
  mdiCheck,
  mdiAlertCircleOutline,
  mdiMinusCircle
} from '@mdi/js'
import ConfirmModal from '@/components/modals/ConfirmModal'
import debounce from 'lodash/debounce'

export default {
  props: {
    questionId: {}
  },

  components: {
    ConfirmModal
  },

  data() {
    return {
      deleteConfirmation: false,
      loading: false,
      icons: {
        arrowLeft: mdiChevronLeft,
        check: mdiCheck,
        alert: mdiAlertCircleOutline,
        delete: mdiMinusCircle
      },
      form: new Form({
        body: '',
        description: '',
        is_multiple: 0,
        choices: [],
        tips: [],
        sub_questions: [],
        type: 'project-type'
      }),
      tipInput: '',
      choiceInput: '',
      subQuestionInput: '',
      resourceInput: '',
      searchResourceName: '',
      tempResources: '',
      resourceIsLoading: false
    }
  },

  computed: {
    ...mapState({
      resources: state => state.resource.list,
      question: state => state.question.questionDetails
    }),

    resourcesItems() {
      return this.resources.map(resource => ({
        id: resource.id,
        name: resource.title
      }))
    },

    displayName() {
      return this.form.body
    }
  },

  watch: {
    searchResourceName: debounce(async function(searchInput) {
      if (this.resourceIsLoading) return

      this.resourceIsLoading = true

      await this.getResources({
        search: searchInput,
        sort: 'title'
      })

      this.resourceIsLoading = false
      this.resourceInput = ''
    }, 200)
  },

  async created() {
    if (this.questionId) {
      await this.loadQuestion()
    }
  },

  methods: {
    ...mapActions({
      getQuestionById: 'question/getQuestion',
      deleteOnboardingQuestion: 'question/deleteQuestion',
      getResources: 'resource/getResources'
    }),

    onAddResource(cIndex) {
      this.form.choices[cIndex].resources.push(this.resourceInput)
    },

    onRemoveResource(cIndex, rIndex) {
      this.form.choices[cIndex].resources.splice(rIndex, 1)
    },

    onAddChoice() {
      if (this.choiceInput) {
        this.form.choices.push({ name: this.choiceInput, resources: [] })
      }
      this.choiceInput = ''
    },

    onRemoveChoice(index) {
      this.form.choices.splice(index, 1)
    },

    onAddTip() {
      if (this.tipInput) {
        this.form.tips.push({ name: this.tipInput })
      }
      this.tipInput = ''
    },

    onRemoveTip(index) {
      this.form.tips.splice(index, 1)
    },

    onAddSubQuestion() {
      if (this.subQuestionInput) {
        this.form.sub_questions.push({ name: this.subQuestionInput })
      }
      this.subQuestionInput = ''
    },

    onRemoveSubQuestion(index) {
      this.form.sub_questions.splice(index, 1)
    },

    async loadQuestion() {
      await this.getQuestionById(this.questionId)

      this.form.body = this.question.body
      this.form.description = this.question.description
      this.form.is_multiple = this.question.is_multiple

      this.form.choices = this.question.choices.map(choice => ({
        name: choice.name,
        resources: choice.resources
          ? choice.resources.map(resource => ({
              id: resource.id,
              name: resource.title
            }))
          : []
      }))

      if (this.question.sub_questions) {
        this.form.sub_questions = this.question.sub_questions.map(sub => ({
          name: sub
        }))
      }

      if (this.question.tips) {
        this.form.tips = this.question.tips.map(tip => ({
          name: tip
        }))
      }
    },

    submit() {
      this.$emit('submit', this.form)
    },

    async deleteQuestion() {
      await this.deleteOnboardingQuestion(this.questionId)

      this.deleteConfirmation = false

      this.$store.dispatch('showSnackbar', {
        message: 'Onboarding question deleted',
        color: 'success'
      })

      this.$router.replace({
        name: 'onboarding-questions'
      })
    }
  }
}
</script>
